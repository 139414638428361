import React, { useEffect, useRef } from 'react';
let Globe = () => null; // ! Globe component needs to be dyanmically rendered otherwise window breaks on ssr build.
if (typeof window !== 'undefined') Globe = require('react-globe.gl').default;

export default function Globe3d() {
  const globeEl = useRef();

  const rotateGlobal = () => {
    globeEl.current.pointOfView(
      { lat: 40, lng: window?.scrollY / 3, altitude: 2 },
      0
    );
  };

  useEffect(() => {
    globeEl.current.controls().autoRotate = false;
    globeEl.current.controls().autoRotateSpeed = 0.5;
    globeEl.current.pointOfView({ lat: 20, lng: 0, altitude: 2 }, 1000);
    document.addEventListener('scroll', rotateGlobal);
    return () => {
      document.removeEventListener('scroll', rotateGlobal);
    };
  }, []);

  const N = 20;
  const arcsData = [...Array(N).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: [
      ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)],
      ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)],
    ],
  }));
  return (
    <>
      <Globe
        ref={globeEl}
        // globeImageUrl='//unpkg.com/three-globe/example/img/earth-night.jpg'
        // globeImageUrl='https://res.cloudinary.com/dpakxm3nm/image/upload/v1622957538/portfolio/satellite-map-of-the-world_wm00875_yu5xls.jpg'
        globeImageUrl='https://res.cloudinary.com/dpakxm3nm/image/upload/portfolio/globe-night.jpg'
        arcsData={arcsData}
        arcColor={'color'}
        arcDashLength={() => Math.random()}
        arcDashGap={() => Math.random()}
        arcDashAnimateTime={() => Math.random() * 4000 + 500}
        backgroundColor={'#eaeaea'}
        enablePointerInteraction={false}
      />
    </>
  );
}
