function percentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}

export default (id) => {
  const section = document.getElementById(id);
  const screenHeight = window.innerHeight; // i.e 900
  const screenPosition = window.scrollY; // i.e 0

  if (
    section.getBoundingClientRect().top < screenHeight &&
    section.getBoundingClientRect().top > 0
  ) {
    section.style.opacity =
      (percentage(
        screenHeight - section.getBoundingClientRect().top,
        screenHeight
      ) /
        140) *
      1.5;
  } else if (section.getBoundingClientRect().top < 0) {
    section.style.opacity =
      ((percentage(
        -screenHeight - section.getBoundingClientRect().top,
        screenHeight
      ) *
        -1) /
        140) *
      1.5;
  }
};
