import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Heading, Text, Link as ExternalLink } from 'theme-ui'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal'
import { VscRepoClone, VscGitMerge, VscGitPullRequest } from 'react-icons/vsc'
import { FaProjectDiagram } from 'react-icons/fa'
import { IoPeopleSharp, IoLogoGithub, IoPersonAdd } from 'react-icons/io5'
import Globe3d from '../UI/Globe3d'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default function Projects() {
  const data = useStaticQuery(graphql`
    query githubUserQuery {
      githubData {
        data {
          user {
            name
            avatarUrl
            repositories {
              totalCount
            }
            following {
              totalCount
            }
            followers {
              totalCount
            }
            contributionsCollection {
              contributionYears
              contributionCalendar {
                totalContributions
                weeks {
                  contributionDays {
                    contributionCount
                    contributionLevel
                    date
                    weekday
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const githubUserData = data?.githubData?.data?.user

  const contributions =
    githubUserData.contributionsCollection.contributionCalendar.weeks

  const lastYearContributions =
    githubUserData.contributionsCollection.contributionCalendar
      .totalContributions

  const lastMonthContributions = contributions.slice(
    Math.max(contributions.length - 4, 1) // grab the last four weeks contributions in the list
  )

  const onGithubSince = Math.min(
    ...githubUserData.contributionsCollection.contributionYears
  )

  const lastMonthContributionsCount = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue // standard reducer fucntion
    // grabs all the contributions by adding up all the contributions in the last month day by day
    const monthContributions = lastMonthContributions.map((week) => {
      const weekContributions = week.contributionDays.map(
        (day) => day.contributionCount
      )
      return weekContributions.reduce(reducer) // adds the totals of the array created from the map
    })

    return monthContributions.reduce(reducer) // adds the totals of the array created from the map
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.textContainer}>
        <Fade left cascade>
          <Heading as='h2' sx={styles.title}>
            Projects
          </Heading>
          <Heading as='h3' sx={styles.subtitle}>
            The Circle Of Life
          </Heading>
          <Text as='p' sx={styles.text}>
            Planning - Analysis - Design - Implementation - Testing and
            Intergration - Maintenance
          </Text>

          <Box sx={styles.buttonsContainer}>
            <AniLink
              to='/projects'
              rel='noopener'
              paintDrip
              duration={0}
              hex='#e01f5a'
            >
              <FaProjectDiagram />
              See Projects
            </AniLink>
            <ExternalLink
              href='https://www.github.com/stefantrinh1'
              target='_blank'
              rel='noopener'
            >
              <IoLogoGithub /> See Github
            </ExternalLink>
          </Box>

          {/* <Heading sx={styles.subtitle2}>Github Stats</Heading> */}
          <Box sx={styles.statsContainer}>
            <Box sx={styles.statContainer}>
              <Text as='h6' sx={styles.statTitle}>
                Repositories
              </Text>
              <Text as='p' sx={styles.statText}>
                <VscRepoClone />
                {githubUserData.repositories.totalCount}
              </Text>
            </Box>
            <Box sx={styles.statContainer}>
              <Text as='h6' sx={styles.statTitle}>
                Followers
              </Text>
              <Text as='p' sx={styles.statText}>
                <IoPeopleSharp />
                {githubUserData.followers.totalCount}
              </Text>
            </Box>
            <Box sx={styles.statContainer}>
              <Text as='h6' sx={styles.statTitle}>
                Following
              </Text>
              <Text as='p' sx={styles.statText}>
                <IoPersonAdd />
                {githubUserData.following.totalCount}
              </Text>
            </Box>
            <Box sx={styles.statContainer}>
              <Text as='h6' sx={styles.statTitle}>
                Github Member Since
              </Text>
              <Text as='p' sx={styles.statText}>
                <IoLogoGithub />
                {onGithubSince}
              </Text>
            </Box>
            <Box sx={styles.statContainer}>
              <Text as='h6' sx={styles.statTitle}>
                Contributions In The Last Month
              </Text>
              <Text as='p' sx={styles.statText}>
                <VscGitPullRequest />
                {lastMonthContributionsCount()}
              </Text>
            </Box>
            <Box sx={styles.statContainer}>
              <Text as='h6' sx={styles.statTitle}>
                Contributions In The Last Year
              </Text>
              <Text as='p' sx={styles.statText}>
                <VscGitMerge />
                {lastYearContributions}
              </Text>
            </Box>
          </Box>
        </Fade>
      </Box>
      <Box sx={styles.imageContainer}>
        <LazyLoad>
          <Globe3d />
        </LazyLoad>
      </Box>
    </Box>
  )
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: ['auto', '', '100vh'],
    position: 'relative',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },

  textContainer: {
    padding: '2rem',
    position: ['', '', 'absolute'],
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '10',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: ['2rem 1rem 0rem', '2rem', '4rem'],
  },

  title: {
    fontSize: ['2rem', '2.5rem'],
    marginBottom: '0.75rem',
    fontWeight: '400',
    maxWidth: ['', '', '50%'],
    color: '#202020',
  },
  subtitle: {
    fontSize: ['2rem', '2.5rem'],
    opacity: '0.6',
    marginBottom: '1rem',
    color: 'primary',
    maxWidth: ['', '', '50%'],
  },
  text: {
    fontSize: ['1rem', '1.25rem'],
    color: 'text',
    maxWidth: '500px',
    fontWeight: '200',
    marginBottom: '2rem',
    maxWidth: ['', '', '50%'],
  },
  imageContainer: {
    width: ['100%', '', '100%', '100%', '125%'],
    margin: ['', '', '0 -50% 0 auto'],
    position: 'relative',
    canvas: {
      width: ['100% !important'],
      height: 'auto !important ',
    },
    '::after': {
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
    },
  },
  image: {
    width: '100%',
    maxWidth: 'unset',
  },

  subtitle2: {
    marginBottom: '1rem',
  },

  statsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 0.25rem)',
    maxWidth: ['', '', '50%'],
    margin: '0rem 0rem 0rem -0.25rem',
  },
  statContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: ['calc(33.33% - 0.5rem)', '', 'calc(33.33% - 1rem)'],
    margin: ['0.25rem', '', '0.5rem 1rem 0.5rem 0rem'],
    backgroundColor: '#f4f4f4',
    borderRadius: '10px',
    padding: '0.75rem',
    color: '#286a8b',
  },

  statTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSIze: '0.7rem',
    fontWeight: 'normal',
    marginBottom: '0.5rem',
    opacity: '0.6',
  },

  statText: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    textAlign: 'left',
    margin: 'auto 0 0',
    svg: {
      marginRight: '0.5rem',
      minHeight: '20px',
      minWidth: '20px',
      maxHeight: '25px',
      maxWidth: '25px',
    },
  },

  buttonsContainer: {
    display: 'flex',
    marginBottom: '2rem',
    a: {
      variant: 'buttons.primary',
      margin: ['0 0.75rem 0 0'],
    },
    'a:nth-of-type(2)': {
      backgroundColor: 'brandGreen',
      borderColor: 'brandGreen',
      margin: ['0rem', '', '0 0.75rem 0 0'],
      ':hover': {
        color: 'brandGreen',
        backgroundColor: 'transparent',
      },
    },
    svg: {
      marginRight: '0.5rem',
      minHeight: '20px',
      minWidth: '20px',
      maxHeight: '25px',
      maxWidth: '25px',
    },
  },
}
