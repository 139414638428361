import React, { useEffect, useContext, useState, useRef } from 'react';
import { Box, Heading, Link as ExternalLink } from 'theme-ui';
import Fade from 'react-reveal/Fade';
import { IoCaretDownOutline } from 'react-icons/io5';
import { GrInstagram, GrGithub, GrLinkedin } from 'react-icons/gr';
import { store } from '../Context/Store';
import Hero from '../components/HomepageSections/Hero';
import Engineering from '../components/HomepageSections/Engineering';
import Design from '../components/HomepageSections/Design';
import Projects from '../components/HomepageSections/Projects';
import Career from '../components/HomepageSections/Career';
import Social from '../components/HomepageSections/Social';
import Contact from '../components/HomepageSections/Contact';

import { AccessibilityWidget } from 'react-accessibility';

const navItems = [
  'About',
  'Engineering',
  'Design',
  'Projects',
  'Career',
  'Social',
  'Contact',
];

const IndexPage = () => {
  const { globalState, dispatch } = useContext(store);

  const sideMenu = useRef();
  const numberItems = navItems?.length;
  const paddingBottomAmount = 4;
  const changePosition = 0.2;

  const [cursorPosition, setCursorPosition] = useState({
    top: `0px`,
  });

  useEffect(() => {
    const positionCalculate =
      sideMenu.current.clientHeight / numberItems / 2 - paddingBottomAmount;
    setCursorPosition({
      top: `${positionCalculate}px`,
      width: '70px',
      transition: 'all ease-in-out 0.25s',
    });
    return () => {};
  }, []);

  useEffect(() => {
    const itemHeight = sideMenu.current.clientHeight / numberItems;
    const halfItemHeight = itemHeight / 2 + paddingBottomAmount; // itemheight divided by two and + 4 for the paddingBottom
    const maxTopPosition = itemHeight * numberItems - halfItemHeight;
    const scrollAdjust = () => {
      const screenHeight = window.innerHeight; // i.e 900
      const screenPosition = window.scrollY; // i.e 0
      const currentIndexScreenPosition =
        Math.ceil(screenPosition / screenHeight - changePosition) + 1; // i.e 0 or 1 or 2 etc.
      const currentPosition =
        itemHeight * currentIndexScreenPosition - halfItemHeight; // ticker position in px i.e 23.5

      // if the position has changed then change state
      if (currentIndexScreenPosition !== globalState.homepagePosition) {
        const position =
          currentPosition >= maxTopPosition ? maxTopPosition : currentPosition; // caps the movement to the max position
        setCursorPosition({
          top: `${position}px`,
          width: '70px',
          transition: 'all ease-in-out 0.25s',
        });

        dispatch({
          type: 'SET_HOMEPAGE_POSITION',
          position: currentIndexScreenPosition,
        });
      }
    };
    document.addEventListener('scroll', scrollAdjust);
    return () => {
      document?.removeEventListener('scroll', scrollAdjust);
    };
  }, [globalState.homepagePosition]);

  return (
    <Box as='main' sx={styles.container}>
      <Box sx={styles.mainContentContainer}>
        <Hero />
        <Engineering />
        <Design />
        <Projects />
        <Career />
        <Social />
        <Contact />
      </Box>

      <Box sx={styles.sideBar}>
        <Fade top delay={1000}>
          <Heading as='h3' sx={styles.navTitle}>
            0{globalState.homepagePosition}
          </Heading>
        </Fade>
        <Box
          id='sidebarMenu'
          ref={sideMenu}
          className='sidebarMenu'
          sx={styles.sidebarMenu}
        >
          {navItems.map((item, index) => {
            return (
              <Fade
                key={`sidebarNavItem-${item}`}
                top
                delay={1000 + 200 * index}
              >
                <Heading
                  as='h4'
                  sx={styles.navItem}
                  onClick={() => {
                    window?.scrollTo({
                      top: window.innerHeight * index,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {item}
                </Heading>
              </Fade>
            );
          })}
          <Box sx={{ ...styles.cursor, ...cursorPosition }} />
        </Box>
        <Box sx={styles.iconsContainer}>
          <ExternalLink
            href='https://www.github.com/stefantrinh1'
            target='_blank'
            rel='noopener'
          >
            <GrGithub />
          </ExternalLink>
          <ExternalLink
            href='https://www.linkedin.com/in/stefantrinh1/'
            target='_blank'
            rel='noopener'
          >
            <GrLinkedin />
          </ExternalLink>
          <ExternalLink
            href='https://www.instagram.com/stefan.codes/'
            target='_blank'
            rel='noopener'
          >
            <GrInstagram />
          </ExternalLink>
        </Box>
      </Box>
      {globalState.homepagePosition >= numberItems ? (
        ''
      ) : (
        <Box
          sx={styles.scrollDownButton}
          onClick={() => {
            window?.scrollTo({
              top: window.innerHeight * globalState.homepagePosition + 1,
              behavior: 'smooth',
            });
          }}
        >
          <IoCaretDownOutline />
        </Box>
      )}

      <AccessibilityWidget />
    </Box>
  );
};

export default IndexPage;

const styles = {
  container: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
  },
  mainContentContainer: {
    width: ['100%', '', '', '80%'],
    backgroundColor: 'backgroundSecondary',
    overflow: 'hidden',
  },

  sideBar: {
    position: 'fixed',
    right: '0',
    top: '0',
    height: '100vh',
    display: ['none', '', '', 'flex'],
    flexDirection: 'column',
    width: ['', '', '', '20%'],
    backgroundColor: 'background',
    color: 'text',
    padding: '6rem 1rem 2rem',
    // position: 'relative',
  },
  navTitle: {
    fontSize: '8rem',
    writingMode: 'tb',
    transform: 'rotate(180deg)',
    color: 'lightGrey',
    marginBottom: '5rem',
    fontWeight: '400',
  },

  sidebarMenu: {
    position: 'relative',
  },
  cursor: {
    width: '0px',
    height: '2px',
    backgroundColor: 'black',
    position: 'absolute',
    top: '0px',
    left: '-60px',
    transition: 'all ease-in-out 3s',
  },
  navItem: {
    fontSize: '1.2rem',
    padding: '0.5rem 1.5rem',
    fontWeight: '100',
    color: 'text',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
    transition: ' all ease-in-out 0.4s',
    '::after': {
      marginTop: '0.5rem',
      height: '2px',
      transition: ' all ease-in-out 0.7s',
      width: '0px',
      content: "''",
      backgroundColor: 'primary',
    },
    ':hover': {
      // opacity: '0.4',
      color: 'primary',
      '::after': {
        width: '100%',
      },
    },
  },
  iconsContainer: {
    margin: 'auto auto 0rem 1.5rem',
    fontSize: '2.25rem',
    a: {
      color: 'primary',
      transition: 'all ease-in-out 0.7s',
      ':hover': {
        color: 'lightgrey',
      },
    },
    svg: {
      marginRight: '0.5rem',
    },
  },
  scrollDownButton: {
    position: 'fixed',
    bottom: '1rem',
    left: '1rem',
    zIndex: '20',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    backgroundColor: 'primary',
    color: 'light',
    borderRadius: '5px',
    transition: 'all ease-in-out 0.6s',
    border: 'solid 1px',
    borderColor: 'background',
    ':hover': {
      color: 'dark',
      backgroundColor: 'transparent',
      border: 'solid 1px',
    },
  },
};
