import React from 'react';
import { Image } from 'theme-ui';
import shortid from 'shortid';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoad from 'react-lazyload';

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <FaChevronRight {...props} />
);

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <FaChevronLeft {...props} />
);

export default function Carousel({ images, settings, defaultSettings }) {
  const settingCombined = {
    ...defaultSettings,
    ...settings,
  }; // combines the settings allows settings to be overwritten rather than needed to write entire settings again

  return (
    <Slider {...settingCombined}>
      {images.map((image, index) => (
        <LazyLoad key={shortid.generate()}>
          <Image src={image} />
        </LazyLoad>
      ))}
    </Slider>
  );
}

const defaultSettingsProp = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

Carousel.defaultProps = {
  settings: defaultSettingsProp,
  defaultSettings: defaultSettingsProp,
  images: [
    'https://res.cloudinary.com/dpakxm3nm/image/upload/v1623197451/portfolio/placeholder_h66nrb.png',
    'https://res.cloudinary.com/dpakxm3nm/image/upload/v1623197451/portfolio/placeholder_h66nrb.png',
    'https://res.cloudinary.com/dpakxm3nm/image/upload/v1623197451/portfolio/placeholder_h66nrb.png',
  ],
};
