import React, { useEffect } from 'react'
import { Box, Image, Heading, Text } from 'theme-ui'
import { Link } from 'gatsby'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal'
import fadeInOutElement from '../../helpers/FadeInOutElement'
import { SiVisualstudiocode } from 'react-icons/si'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default function Engineering() {
  const fadeInOut = () => {
    fadeInOutElement('laptopImage')
  }

  useEffect(() => {
    document.addEventListener('scroll', fadeInOut)
    return () => {
      document.removeEventListener('scroll', fadeInOut)
    }
  }, [])

  return (
    <Box sx={styles.container} id='engineeringContainer'>
      <Box sx={styles.textContainer}>
        <Fade left cascade>
          <Heading as='h2' sx={styles.title}>
            Engineering
          </Heading>
          <Heading as='h3' sx={styles.subtitle}>
            Full Stack Engineer
          </Heading>
          <Text as='p' sx={styles.text}>
            Front End and Backend, Its all the same. I enjoy being a generalist.
            Give me a problem and I'll tackle it, give me a product and I'll
            build it.
          </Text>
          <Box sx={styles.buttonsContainer}>
            <AniLink
              to='engineering'
              rel='noopener'
              paintDrip
              duration={0}
              hex='#e01f5a'
            >
              <SiVisualstudiocode />
              My Engineering Stack
            </AniLink>
          </Box>
        </Fade>
      </Box>

      <Box sx={styles.imageContainer} id='laptopImage'>
        <LazyLoad>
          <Fade right>
            <Image
              sx={styles.image}
              src='https://res.cloudinary.com/dpakxm3nm/image/upload/v1622691794/portfolio/laptopcode_l79gtd.png'
              alt='laptop with code'
            />
          </Fade>
        </LazyLoad>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: ['auto', '', '100vh'],
    position: 'relative',
    backgroundColor: 'transparent',
  },

  textContainer: {
    width: ['100%', '', '50%'],
    padding: ['3rem 1rem 1rem', '', '2rem'],
  },

  title: {
    fontSize: ['2rem', '2.5rem'],
    marginBottom: '0.75rem',
    fontWeight: '400',
  },
  subtitle: {
    fontSize: ['2rem', '2.5rem'],
    opacity: '0.6',
    marginBottom: '2rem',
    color: 'primary',
  },
  text: {
    fontSize: ['1rem', '1.25rem'],
    color: 'text',
    maxWidth: '500px',
    fontWeight: '200',
    marginBottom: '2rem',
  },
  imageContainer: {
    width: ['100%', '', '50%'],
    opacity: '0',
    padding: ['0rem 1rem', '', '0rem'],
    // backgroundColor: 'red',
  },
  image: {
    width: ['130%', '', '200%'],
    maxWidth: 'unset',
  },
  buttonsContainer: {
    display: 'flex',
    marginBottom: '2rem',
    a: {
      variant: 'buttons.primary',
      margin: '0 0.75rem 0 0',
    },
    'a:nth-of-type(2)': {
      backgroundColor: 'brandGreen',
      borderColor: 'brandGreen',
      ':hover': {
        color: 'brandGreen',
        backgroundColor: 'transparent',
      },
    },
    svg: {
      marginRight: '0.5rem',
      minHeight: '20px',
      minWidth: '20px',
      maxHeight: '25px',
      maxWidth: '25px',
    },
  },
}
