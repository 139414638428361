import React from 'react'
import { Heading, Box, Text } from 'theme-ui'
// import Particles from 'react-particles-js';
import Fade from 'react-reveal/Fade'
import LazyLoad from 'react-lazyload'

export default function Engineering() {
  return (
    <Box sx={styles.container}>
      <Fade>
        <Box sx={styles.background}>
          <LazyLoad>
            {/* <Particles
              height={'100vh'}
              width={'100vw'}
              params={particlesParams}
            /> */}
          </LazyLoad>
        </Box>
      </Fade>

      <Box sx={styles.textContent}>
        <Heading as='h1' sx={styles.title}>
          Stefan Trinh
        </Heading>

        <Heading as='h2' sx={styles.subtitle}>
          Software Engineer
        </Heading>

        <Text as='p' sx={styles.text}>
          The final product is the goal. Making software products that are fast,
          reliable and visionally stunning.
        </Text>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    position: 'relative',
    backgroundColor: 'transparent',
  },
  background: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
  },
  textContent: {
    flexDirection: 'column',
    padding: ['1rem', '2rem', '4rem'],
    zIndex: '10',
    marginLeft: ['1rem', '', '2rem'],
    background:
      'radial-gradient(circle, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 70%, rgba(234,234,234,0) 90%)',
    backgroundPosition: 'center center',
    backgroundSize: '100%',
  },
  title: {
    fontSize: ['2rem', '4rem'],
    marginBottom: '0.75rem',
    fontWeight: '400',
  },
  subtitle: {
    fontSize: ['2rem', '4rem'],
    // opacity: '0.6',
    marginBottom: '2rem',
    color: 'primary',
  },
  text: {
    fontSize: ['1rem', '1.5rem'],
    color: 'text',
    maxWidth: '500px',
    fontWeight: '200',
  },
}

// ? ==================================
// ? =====  Particles Settings  =======
// ? ==================================

const particlesParams = {
  fpsLimit: 60,
  particles: {
    number: {
      value: 50,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: ['#2EB67D', '#ECB22E', '#E01E5B', '#36C5F0'],
    },
    opacity: {
      value: 1,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 20,
      random: true,
      anim: {
        enable: false,
        speed: 10,
        size_min: 10,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: '#808080',
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 140,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
}
