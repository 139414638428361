import React, { useEffect } from 'react';
import { Box, Heading, Text } from 'theme-ui';
import Fade from 'react-reveal';
import { RiGalleryFill } from 'react-icons/ri';
import { FaPlaneDeparture, FaBlogger } from 'react-icons/fa';
import Carousel from '../UI/Carousel';
import { useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default function Social() {
  const [imageLinks, setImageLinks] = useState(null);

  useEffect(() => {
    const windowWidth = () => {
      if (window) {
        return window?.innerWidth;
      } else {
        return '2000';
      }
    };

    const createImageLinks = () => {
      const links = [];
      for (let index = 0; index < 7; index++) {
        // const imageSize = Math.round(windowWidth() / 1.1)
        const imageSize = 2000;

        const url = `https://res.cloudinary.com/dpakxm3nm/image/upload/w_${imageSize}/v1626840250/portfolio/homepage/homepage-social${
          index + 1
        }.jpg`;
        links.push(url);
      }
      return links;
    };

    setImageLinks(createImageLinks());
    return () => {};
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.sideBySide}>
        <Box sx={styles.textContainer}>
          <Fade left cascade>
            <Heading as='h2' sx={styles.title}>
              Social
            </Heading>
            <Heading as='h3' sx={styles.subtitle}>
              The Other Side Of Life
            </Heading>
          </Fade>
        </Box>
        <Box sx={styles.carouselContainer}>
          {imageLinks ? <Carousel images={imageLinks} /> : ''}
        </Box>
      </Box>
      <Box sx={styles.boxes}>
        <Box sx={styles.box}>
          <FaPlaneDeparture />
          <Heading as='h5'>Lets Travel There</Heading>

          <Text as='p'>
            The world has so much to offer from Hiking, Traveling, International
            Cuisines and much more.
          </Text>
          {/* <Link to='social' rel="noopener">Lets Go {'>'}</Link> */}
        </Box>

        <Box sx={styles.box}>
          <FaBlogger />
          <Heading as='h5'>Something To Read</Heading>

          <Text as='p'>
            Want to know something? I share knowledge, experiences and
            adventures here.
          </Text>

          <AniLink
            to='blog'
            rel='noopener'
            paintDrip
            duration={0}
            hex='#e01f5a'
          >
            Read more {'>'}
          </AniLink>
        </Box>

        <Box sx={styles.box}>
          <RiGalleryFill />
          <Heading as='h5'>Gallery To View </Heading>

          <Text as='p'>
            Collage all my adventures through photography. Amazing photos I just
            want to share.
          </Text>

          <AniLink
            to='gallery'
            rel='noopener'
            paintDrip
            duration={0}
            hex='#e01f5a'
          >
            View Gallery {'>'}
          </AniLink>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', 'column'],
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: ['auto', '', '100vh'],
    position: 'relative',
    backgroundColor: 'transparent',
    // overflowX: 'hidden',
  },

  sideBySide: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    '::before': {
      content: "''",
      width: ['150px', '', '350px'],
      height: ['150px', '', '350px'],
      position: 'absolute',
      left: ['1.5rem', '', '-10%', '-5%'],
      top: ['30%', '', '-10%', '-5%'],
      backgroundColor: '#bbe2d1',
      borderRadius: '500px',
    },
  },
  carouselContainer: {
    width: ['80%', '50%'],
    margin: '0 0 0 auto',
    borderRadius: '10px 0px 0px 10px',
    overflow: 'hidden',
    '.slick-arrow': {
      color: 'primary',
      zIndex: '1',
      padding: '0.25rem',
      backgroundColor: 'dark',
      height: '2rem',
      width: '2rem',
      transform: 'unset',
      ':hover': {
        backgroundColor: 'primary',
        color: 'white',
      },
    },
    '.slick-prev': {
      left: 'unset',
      top: 'unset',
      bottom: '0rem',
      right: '2rem',
    },
    '.slick-next': {
      top: 'unset',
      bottom: '0rem',
      right: '0rem',
    },
    '.slick-dots': {
      display: 'flex !important',
      flexDirection: 'column',
      bottom: '0rem',
      left: '0rem',
      li: {
        height: '30px',
        width: '30px',

        button: {
          '::before': {
            color: 'primary',
            fontSize: '10px',
          },
        },
      },
      'li.slick-active': {
        color: 'primary',
      },
    },
    '.slick-slide > div': {
      display: 'flex',
      height: '500px',
    },
    img: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
  },

  textContainer: {
    width: ['100%', '50%'],
    padding: ['1rem', '', '0rem 2rem'],
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    fontSize: ['2rem', '2.5rem'],
    marginBottom: '0.75rem',
    fontWeight: '400',
    textAlign: ['left', '', 'left'],
  },
  subtitle: {
    fontSize: ['2rem', '2.5rem'],
    opacity: '0.6',
    marginBottom: ['0rem', '', '2rem'],
    color: 'primary',
    textAlign: ['left', '', 'left'],
  },

  boxes: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: '1rem',
  },
  box: {
    width: ['100%', '50%', '', '25%'],
    padding: ['1rem 0rem 1.5rem', '', '1rem'],
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    svg: {
      minWidth: '40px',
      minHeight: '40px',
      color: 'brandGreen',
      marginBottom: '0.5rem',
    },
    h5: {
      fontSize: '1rem',
      marginBottom: '1rem',
    },
    p: {
      opacity: '0.7',
      marginBottom: ['1rem', '', '1.5rem'],
    },

    a: {
      color: 'primary',
      margin: 'auto 0rem 0rem',
      fontWeight: 'bold',
    },
  },
};
