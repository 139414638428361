import React from 'react'
import { Box, Heading, Text, Input, Textarea, Button, Label } from 'theme-ui'
import { GrMail, GrGithub, GrInstagram } from 'react-icons/gr'

export default function Contact() {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.contactInfo}>
        <Heading as='h4'>Contact Information</Heading>
        <Text as='p'>
          Any questions or queries, please use the form and I will get back to
          you soon.
        </Text>
        <Text as='p'>
          <GrMail />
          stefantrinh1.work@gmail.com
        </Text>
        <Text as='p'>
          <GrGithub />
          stefantrinh1
        </Text>
        <Text as='p'>
          <GrInstagram />
          @stefan.codes
        </Text>
      </Box>

      <Box
        sx={styles.contactForm}
        as='form'
        name='contactform'
        method='post'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <Heading as='h4'>Send Us A Message</Heading>
        <Input type='hidden' name='form-name' value='contactform' />
        <Box sx={styles.nameContainer}>
          <Label htmlFor='name'>Name</Label>
          <Input name='name' type='text' id='name' />
        </Box>
        <Box sx={styles.emailContainer}>
          <Label htmlFor='email'>Email</Label>
          <Input type='email' name='email' id='email' />
        </Box>
        <Box sx={styles.phoneContainer}>
          <Label htmlFor='phone'>Phone</Label>
          <Input type='tel' name='email' id='email' />
        </Box>
        <Box sx={styles.messageContainer}>
          <Label htmlFor='message'>Message</Label>
          <Textarea name='message' id='message' rows='6' />
        </Box>
        <Button variant={'buttons.primary'} type='submit'>
          Send
        </Button>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    alignItems: 'stretch',
    width: '100%',
    // height: '50vh',
    position: 'relative',
    // backgroundColor: 'transparent',
    backgroundColor: 'light',
    // borderTop: '2px solid black',
  },

  contactInfo: {
    width: ['100%', '', '40%'],
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem 2rem',
    position: 'relative',
    backgroundColor: 'primary',
    borderRadius: '0px 20px 0px 0px',
    overflow: 'hidden',
    color: 'light',
    h4: {
      marginBottom: '2.5rem',
      fontSize: '1.75rem',
    },
    p: {
      marginBottom: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      fontSIze: '1.2rem',
      svg: {
        marginRight: '0.5rem',
      },
    },
    '::before': {
      width: '175px',
      height: '175px',
      borderRadius: '500px',
      content: "''",
      backgroundColor: '#b7e4c7',
      right: '0',
      bottom: '0',
      transform: ['translate(10%, 10%)', '', 'unset'],
      position: 'absolute',
    },
    '::after': {
      width: '300px',
      height: '300px',
      borderRadius: '500px',
      content: "''",
      backgroundColor: 'brandGreen',
      right: '0',
      bottom: '0',
      transform: 'translate(50%, 50%)',
      position: 'absolute',
    },
  },
  contactForm: {
    width: ['100%', '60%'],
    padding: ['4rem 2rem', '', '4rem'],
    h4: {
      marginBottom: '2.5rem',
      fontSize: '1.75rem',
      color: 'brandGreen',
    },
    label: {
      fontSize: '0.9rem',
      color: 'primary',
      opacity: '0.9',
    },
    input: {
      border: 'none',
      borderBottom: 'solid 1px lightgrey',
      marginBottom: '1rem',
      borderRadius: '0px',
      ':focus': {
        outline: 'none',
        borderColor: 'primary',
      },
    },
    textArea: {
      border: 'none',
      borderBottom: 'solid 1px lightgrey',
      borderRadius: '0px',
      resize: 'none',
      ':focus': {
        outline: 'none',
        borderColor: 'primary',
      },
    },
    button: {
      margin: ['1.5rem 0rem', '', '1rem 0rem 0rem auto'],
      padding: '1rem 5rem',
    },
  },
}
